/* <LinkMetamask /> */


/* Components */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faLink } from '@fortawesome/free-solid-svg-icons'
import MetaMaskOnboarding from '@metamask/onboarding'

/* Media */
import styles from './LinkMetamask.module.css'

const axios = require('axios')
const _ = require('lodash')

// const chroma_endpoint = "https://api.chroma.vision"
const chroma_endpoint = "http://localhost:3030"
const chroma_link_endpoint = chroma_endpoint + "/metamask/link"
const chroma_verify_endpoint = chroma_endpoint + "/metamask/verify"


function LinkMetamask(props) {
    const callback = _.get(props, "callback", (verified)=>{})

    const isMetamaskInstalled = () => {
        //Have to check the ethereum binding on the window object to see if it's installed
        const { ethereum } = window
        return Boolean(ethereum && ethereum.isMetaMask)
    }

    const installMetamask = (e) => {
        const onboarding = new MetaMaskOnboarding({ chroma_endpoint })
        onboarding.startOnboarding()
    }

    const linkMetamask = async (e) => {
        try{
            const { ethereum } = window
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
            
            if(accounts.length > 0){
                let address = accounts[0]

                const query = {
                    address: address
                }
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + _.get(window, "localStorage.chroma_token")
                }
        
                const nonceRequest = await axios.post(chroma_link_endpoint, {query}, {headers: headers})
                const nonce = _.get(nonceRequest, "data")
                const signRequest = await ethereum.request({ method: 'personal_sign', params: [ nonce, address ] });

                // Send signed request to server for verification
                const verifyQuery = {
                    signedMessage: signRequest
                }
                const nonceVerification = await axios.post(chroma_verify_endpoint, {verifyQuery}, {headers: headers})
                const isVerified = _.get(nonceVerification, "data")
                
                callback(isVerified)

            }
        } catch (error) {
            console.log('metamask error', error)
        }
    }
    
    return(
        <div className={styles.UsersDashContainer}>
            <div style={{borderRadius: "8px !important", overflow: "hidden", marginBottom: "0"}}>
                <div style={{padding: "0"}}>
                    {isMetamaskInstalled() ? 
                        <button onClick={linkMetamask} className={`primary`}><FontAwesomeIcon icon={faLink} />  &nbsp;Link Metamask</button> : 
                        <button onClick={installMetamask} className={`primary`}><FontAwesomeIcon icon={faFileDownload} /> &nbsp;Install Metamask</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default LinkMetamask
/* Dashboard */

/* Core */
import { Helmet } from 'react-helmet-async'

/* Data */

/* Components */
import Layout from "../components/layout/Layout"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTint, faTabletAlt, faPlug } from '@fortawesome/free-solid-svg-icons'

/* Media */
import styles from './Resources.module.css'

import NewsletterForm from 'components/input/NewsletterForm'

function Resources(props) {
    return (
        <Layout noContainer="true">
            <Helmet>
                <title>CHROMA | Resources</title>
            </Helmet>
            <div className={styles.Resources}>
          

                <h3 className={styles.Header}>Resources</h3>

                <p className={styles.Description}>To assist you on your journey</p>

                <div className={styles.ResourcesContainer}>
                    <div className="container">
                        <div className="row align-items-start">

                            {/* Manual */}
                            <div className="col">
                                <div className={styles.CardContainer}>
                                    <div className="card text-center">
                                        <div className={styles.IconContainer}>
                                            <FontAwesomeIcon className={styles.Icon} icon={faTint} />
                                        </div>
                                        <div className="card-body">
                                            
                                            <h4 className={styles.ResourceHeader}>Microdose<br />Manual</h4>
                                            <a href="https://manual.microdosevr.com" className={styles.Button}>Get Started</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* OSC */}
                            <div className="col">
                                <div className={styles.CardContainer}>
                                    <div className="card text-center">
                                        <div className="card-body">
                                            <div className={styles.IconContainer}>
                                                <FontAwesomeIcon className={styles.Icon} icon={faTabletAlt} />
                                            </div>
                                            <h4 className={styles.ResourceHeader}>OSC<br />Support</h4>
                                            <a href="https://manual.microdosevr.com/osc" className={styles.Button}>Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* Community */}
                            <div className="col">
                                <div className={styles.CardContainer}>
                                    <div className="card text-center">
                                        <div className="card-body">
                                            <div className={styles.IconContainer}>
                                                <FontAwesomeIcon className={styles.Icon} icon={faPlug} />
                                            </div>
                                            <h4 className={styles.ResourceHeader}>Discord<br />Community</h4>
                                            <a href="http://microdose.chat" className={styles.Button}>Join Us!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        {/* Newsletter Form */}
                        <div className={`${styles.NewsletterContainer} row`}>
                            <div className="col">
                                <p className={styles.NewsletterIntro}>Join our newsletter to stay updated</p>
                                <NewsletterForm />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </Layout>
    )
}

export default Resources
/* <LinkMetamask /> */

/* Data */
import { useEffect, useState } from "react"
import chroma from "@chromaplatform/chromajs"
import { useStores } from "../../context/RootStoreContext"

/* Components */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'

/* Media */
import styles from './LinkMetamask.module.css'

const axios = require('axios')
const _ = require('lodash')

const chroma_endpoint = "https://api.chroma.vision"
// const chroma_endpoint = "http://localhost:3030"
const chroma_unlink_endpoint = chroma_endpoint + "/metamask/unlink"


function UnlinkWallet(props) {
    const rootStore = useStores()
    const [disabledButton, setDisabledButton] = useState(false)

    const callback = _.get(props, "callback")


    const unlink = async () => {
        setDisabledButton(true)

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + _.get(window, "localStorage.chroma_token")
        }

        const unlinkRequest = await axios.post(chroma_unlink_endpoint, {}, {headers: headers})

        callback()
    }
    
    return(
        <div className={`${styles.UsersDashContainer} ${props.className}`}>
            <div className="card" style={{borderRadius: "8px !important", overflow: "hidden", marginBottom: "0"}}>
                <div className="card-body" style={{padding: "0"}}>
                    <button disabled={disabledButton} onClick={unlink} className={`${styles.UnlinkWallet} primary`}>
                        <FontAwesomeIcon icon={faPowerOff} className={styles.unlinkIcon} /> Unlink Wallet
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UnlinkWallet
/* <AdminDash /> */

import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Charts - move to sub component */
import CircularProgress from '@material-ui/core/CircularProgress'

/* Components */


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglassHalf, faMoneyBill, faSearch, faUserAstronaut } from '@fortawesome/free-solid-svg-icons'


/* Media */
import styles from './AdminDash.module.css'
import QueryBuilder from "components/query-builder/QueryBuilder"
import ChromaList from "CHROMA-React/components/chroma-list"

const _ = require('lodash')
const moment = require('moment')

function AdminDash(props) {
    /* Local State */
    const [users, setUsers] = useState({
		users: [],
		userCount: 0
	})
	const [timePlayed, setTimePlayed] = useState(0)
  	const [minutesPlayed, setMinutesPlayed] = useState(0)
	const [seedcodes, setSeedcodes] = useState([])
	const [transactions, setTransactions] = useState([])
  	const [usersOnline, setUsersOnline] = useState()
	const [usersOnlineThisWeek, setUsersOnlineThisWeek] = useState()
	const [usersOnlineThisMonth, setUsersOnlineThisMonth] = useState()


	
  /* Get Users data */
  useEffect(() => {
  let totalTimePlayed = 0

      const getUsers = async () => {
          const usersQuery = {
              service: "users",
              fields: "_id username createdAt meta{origin{seedcode} appData{MDVR{sessions{minutesPlayed}}} geolocation{current{city region}}}"
          }
  
          const users = await chroma.find(usersQuery)
          let data = {
            users: users,
            userCount: users.length || 0
          }
          setUsers(data)

          totalTimePlayed = users.reduce((a, b) => a + (_.get(b, "meta.appData.MDVR.sessions.minutesPlayed") || 0), 0)
          totalTimePlayed = Math.ceil(totalTimePlayed)

          setTimePlayed(totalTimePlayed)
      }


      getUsers()
  }, [chroma, setUsers, setTimePlayed])

  // GET Minutes Played
  useEffect(() => {
    const getMinutesPlayedData = async () => {
        const timeout = moment().subtract(5, 'minutes').unix()
        const minutesQuery = {
            service: "user-app-data",
            // query: {userId: profile.id, key: "Online"},
            query: {key: "Online"},
            fields: "value"
        }
    
        chroma.find(minutesQuery, true).then(data => {
            const totalMinutes = data.reduce((a, b) => a + (b["value"]["minutes"] || 0), 0)
            setMinutesPlayed(totalMinutes)
        })
    }

    getMinutesPlayedData()

    // Triggers on teardown - Remove Events
    return () => {
        // global.removeEventListener('/user/authenticated', handleAuth)
    }
  }, [setMinutesPlayed])

  // GET Users Online
  useEffect(() => {
    const getUsersOnline = async () => {
		// Last 30 Minutes
        const timeout = moment().subtract(30, 'minutes').unix()
        const minutesQuery = {
			service: "user-app-data",
            // query: {userId: profile.id, key: "Online"},
            query: {key: "Online", updatedAt: {$gt: timeout}},
            fields: "value updatedAt"
        }	
        chroma.find(minutesQuery, true).then(data => {
			setUsersOnline(data.length)
        })
		
		// Last week
		const thisWeek = moment().subtract(60 * 24 * 7, 'minutes').unix()
        const weekQuery = {
			service: "user-app-data",
			query: {key: "Online", updatedAt: {$gt: thisWeek}},
			fields: "value updatedAt"
		}
        chroma.find(weekQuery, true).then(data => {
            setUsersOnlineThisWeek(data.length)
        })

		// Last month
		const thisMonth = moment().subtract(60 * 24 * 7 * 4, 'minutes').unix()
        const monthQuery = {
			service: "user-app-data",
			query: {key: "Online", updatedAt: {$gt: thisMonth}},
			fields: "value updatedAt"
		}
        chroma.find(monthQuery, true).then(data => {
            setUsersOnlineThisMonth(data.length)
        })
    
        
    }

    getUsersOnline()

    // Triggers on teardown - Remove Events
    return () => {
        // global.removeEventListener('/user/authenticated', handleAuth)
    }
}, [setUsersOnline])

	/* Get Seedcode data */
	useEffect(() => {
		const getSeedcodes = async () => {
			const seedcodesQuery = {
				service: "supply/code",
				fields: "_id code totalTokenSupply createdAt updatedAt mode tokensGenerated tokens meta"
			}

			const seedcodes = await chroma.find(seedcodesQuery)
			setSeedcodes(seedcodes)
		}

		getSeedcodes()
	}, [chroma, setSeedcodes])

	 /* Get Transaction data */
	 useEffect(() => {
		const getTransactions = async () => {
			const txQuery = {
				service: "transactions",
				fields: "_id amount medium inMeta{totalUSDRecieved}",
        		query: {status:"complete"}
			}

			const transactions = await chroma.find(txQuery)
			setTransactions(transactions)
		}

		getTransactions()
	}, [chroma, setTransactions])


	const chromaQLSeedcodes = {
		service: "supply/code",
		fields: "_id code mode totalTokenSupply tokensGenerated"
	}

	const chromaQLUsers = {
		service: "users",
		query: {
			"$sort": {
				createdAt: -1
			}
		},
		fields: "_id username email createdAt meta{appData{MDVR{sessions{minutesPlayed}}}}"
	}

	const chromaQLTransactions = {
		service: "transactions",
		query: {
			"status": "complete"
		},
		fields: "_id status createdAt medium amount outMetaType"
	}

    return(
        <div className={styles.AdminDashContainer}>
			<div className="container">
				<div className={styles.Container}>
					<div className="row">

						{/* Left Column */}
						<div className="col-lg-2">
							<div className={styles.QueryBuilderLink}>
								<Link to="/chromaql" style={{width: "100%"}} className="btn btn-block btn-primary">
								<FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
								Query Builder
								</Link>
							</div>
						</div>


						{/* Right Column */}
						<div className="col-lg-10">
							<div className={styles.Section}>
								{/* Three Columns */}
								<div className="row">
								
								{/* Users */}
								<div className="col-sm">
									<div className="card" style={{marginBottom: 18}}>
									<div className={`${styles.CardBody} card-body`} style={{height: "220px", textAlign:"center"}}>
										<h3 style={{fontSize: "14px", marginBottom: "18px", opacity: ".6"}}>
										<FontAwesomeIcon icon={faUserAstronaut} className={styles.AnalyticsTitleIcon} />
										Total Users
										</h3>
										<h2 style={{fontSize: "82px", marginTop: "4px", marginBottom: "0"}}>{users.userCount}</h2>
										<h2 style={{color: "rgba(255,255,255,0.3)" ,fontSize: "14px", marginTop: "4px", marginBottom: "4px"}}>{usersOnline} currently online</h2>
										<h2 style={{color: "rgba(255,255,255,0.3)" ,fontWeight:"400", fontSize: "14px", margin: "4px 0"}}>{usersOnlineThisWeek} this week</h2>
										<h2 style={{color: "rgba(255,255,255,0.3)" ,fontWeight:"400", fontSize: "14px", marginTop: "4px"}}>{usersOnlineThisMonth} this month</h2>
									</div>
									</div>
								</div>
					
								{/* Time Played */}
								<div className="col-sm">
									<div className="card" style={{marginBottom: 18}}>
									<div className={`${styles.CardBody} card-body`} style={{height: "220px", textAlign:"center"}}>
										<h3 style={{fontSize: "14px", marginBottom: "42px", opacity: ".6"}}>
										<FontAwesomeIcon icon={faHourglassHalf} className={styles.AnalyticsTitleIcon} />
										Total Time Played
										</h3>
										<h2 style={{fontSize: "32px", margin: 0, whiteSpace: "nowrap"}}>{Math.ceil((timePlayed + minutesPlayed) / 60)} Hours</h2>
										<h2 style={{fontSize: "12px", margin: "2px 0 0 0", opacity: .3}}>- or -</h2>
										<h2 style={{fontSize: "24px", margin: "4px 0 0 0", opacity: .6}}>{Math.ceil((timePlayed + minutesPlayed) / 60 / 24)} Days</h2>
					
									</div>
									</div>
								</div>
								
								{/* Transactions */}
								<div className="col-sm">
									<div className="card" style={{marginBottom: 0}}>
									<div className={`${styles.CardBody} card-body`} style={{minWidth: "0", height: "220px", padding: "0"}}>
										<div className="card-body" style={{height: "160px", textAlign:"center"}}>
											<h3 style={{fontSize: "14px", marginBottom: "54px", opacity: ".6"}}>
											<FontAwesomeIcon icon={faMoneyBill} className={styles.AnalyticsTitleIcon} />
											Total Sales ({transactions.length})
										</h3>
					
										<h2 className={styles.CurrencyTitleTotal}>
											${parseFloat(transactions.reduce((a, b) => a + _.get(b, "inMeta.totalUSDRecieved", 0), 0)).toFixed(0)}
										</h2>
					
										{/* <h2 className={styles.CurrencyTitle}>
											{parseFloat(transactions.filter(tx => tx.medium === "ltct").reduce((a, b) => a + b.amount, 0)).toFixed(3)}
											<Icon icon={ltcIcon} className={styles.CurrencyIcon} />
										</h2> */}
					
										{!transactions.length > 0 &&
											<CircularProgress disableShrink />
										}
										</div>
									</div>
									</div>
								</div>
								</div>
							</div>
				
							{/* Users */}
							<div className={styles.Section}>
								<QueryBuilder
									header="🧔 Latest Users"
									limit={3}
									// service={"users"}
									chromaQL={chromaQLUsers}
								/>
							</div>

							{/* Transactions */}
							<div className={styles.Section}>
								<QueryBuilder
									header="💸 Latest Transactions"
									limit={3}
									chromaQL={chromaQLTransactions}
									/>
							</div>
            
                        	{/* Seedcodes */}
							{/*<div className={styles.Section}>
							<h2>🌾 Latest Seedcodes</h2>
							<div className={styles.UserContainer}>
								<ChromaListBasic query={chromaQLSeedcodes} limit={6} />
							</div>
				
							</div>*/}
						</div>
					</div>
				</div>
			</div>      
        </div>
    )
}

export default AdminDash
/* Core */
import _ from 'lodash'

/* Media */
import styles from './user.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock, faEnvelope, faIdCard } from '@fortawesome/free-solid-svg-icons'

import Grid from '@mui/material/Grid'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom'

const moment = require('moment')

const UserListItem = (props) => {
    const { item } = props

    const username = _.get(item, "username", "N/A")
    const url = `/${username}`

    return (
        <div {...props} className={`chroma-list-item ${styles.listItemContainer}`}>
            {/* Username */}
            <Link className={styles.link} to={url}>
                <h2 className={styles.name}>{username}</h2>
            </Link>
            <h3 className={styles.id}>{_.get(item, "_id")}</h3>

            <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12}>
                    {/* Join Date */}
                        <p className={styles.date}>
                        <FontAwesomeIcon icon={faCalendar} className={styles.icon} />
                        {moment(_.get(item, "createdAt", 1) * 1000).fromNow()}
                    </p>
                </Grid>

                {_.get(item, "meta.appData.MDVR.sessions.minutesPlayed") ? 
                    <Grid item sm={12} md={12}>
                        {/* Minutes */}
                        <p className={styles.minutes}>
                            <FontAwesomeIcon icon={faClock} className={styles.icon} />
                            {Math.round(_.get(item, "meta.appData.MDVR.sessions.minutesPlayed", 0))}
                        </p>
                    </Grid>
                : ""}
            </Grid>

            {/* ethId */}
            {_.get(item, "ethId") ? 
                <p className={styles.ethId}>
                    <FontAwesomeIcon icon={faEthereum} className={styles.icon} />
                    {_.get(item, "ethId", "N/A")}
                </p> 
                : ""}
        </div>
    )
}

export default UserListItem
/* Dashboard */

/* Core */
import { useEffect, useState } from "react"
import { Helmet } from 'react-helmet-async'
import { Redirect } from "react-router-dom"

/* Data */
import chroma from "@chromaplatform/chromajs"

/* Router */
import { Link } from "react-router-dom"

/* Components */
import { Avatar, Image } from 'antd'


import Hero from "../components/media/Hero"
import Layout from "../components/layout/Layout"
import SocialShare from "../components/utilities/SocialShare"


/* Media */
import styles from './AssetScreenshot.module.css'

const color = require('chroma-js')
const _ = require('lodash')
const moment = require('moment')

function AssetScreenshot(props) {
    const assetId = _.get(props, "match.params.assetId")

    const [asset, setAsset] = useState({})
    const [user, setUser] = useState({})

    useEffect(() => {
        const getUserInfo = async (userId) => {
            const userQuery = {
                service: "users",
                query: {_id: userId},
                fields: "_id createdAt username roles meta{profile style appData{MDVR{sessions{minutesPlayed}}}}"
            }
        
            chroma.find(userQuery, true).then(data => {
                console.log(data)
                const userId = _.get(data[0], '_id')

                const user = {
                    username: _.get(data[0], 'username')
                }
                setUser(user)
            })
        }
        const getAssetInfo = async () => {
            const assetQuery = {
                service: "assets",
                query: {_id: assetId},
                fields: "_id meta type userId subtype createdAt"
            }
        
            chroma.find(assetQuery, true).then(data => {
                const asset = data[0]
                console.log("Asset", asset)
                setAsset(asset)

                // Get user info
                getUserInfo(asset.userId)
            })
        }

        getAssetInfo()

        // Triggers on teardown - Remove Events
        return () => {
            // global.removeEventListener('/user/authenticated', handleAuth)
        }
    }, [assetId, setAsset, setUser])

    // if(profile.notFound){
    //     return(<Redirect to="/404"/>)
    // }

    return (
        <div>
            <Layout noContainer={true}>
                <Helmet>
                    <title>{`CHROMA | Asset`}</title>
                </Helmet>

                {/* Header Image */}
                <Hero 
                    height = {111}
                    primaryImageSource = {_.get(asset, "meta.files[0].path")}
                    color1 = "rgba(0, 0, 0, .84)"
                    color2 = "#1C1C21"
                    />
                
                <div className="container">
                    <div className={styles.AssetContainer}>
                        <div className="row">
                            <div className={`col-lg-3 col-md-12 col-sm-12`}>
                                <div className={styles.Info}>
                                    <h3 className={styles.Title}>{_.get(asset, "_id")}</h3>
                                    <p className={styles.AssetDate}>{moment(_.get(asset, "createdAt") * 1000).format("MM/DD/YYYY")}</p>
                                    <p className={styles.AssetType}>{_.get(asset, "subtype[0]")}</p>
                                </div>
                                <div className={styles.UserInfo}>
                                    <p className={styles.CreatedBy}>Created by</p>
                                    <Link to={`/${_.get(user, "username")}`} className={styles.UserLink}>
                                        <h3>{_.get(user, "username")}</h3>
                                    </Link>
                                </div>
                                <SocialShare />
                            </div>

                            <div className={`${styles.AssetRight} col-lg-9`}>
                                <img src={_.get(asset, "meta.files[0].path")} className={styles.Image} />
                            </div>
                        </div>
                    
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default AssetScreenshot
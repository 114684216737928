/* Dashboard */

/* Core */
import { Link } from "react-router-dom"

import {
    useNavigate,
    useLocation
  } from "react-router-dom"

/* Data */
import { useStores } from "../context/RootStoreContext"

/* Components */
import Hero from "../components/media/Hero"
import Layout from "../components/layout/Layout"
import MetaTags from "../components/utilities/MetaTags"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"

import Slider from "react-slick"

/* Media */
import styles from './Home.module.css'

import logoDark from '../media/images/logo-tall-dark.png'

import slide_1_img from '../media/images/screenshots/Screenshot_A19.jpg'
import slide_2_img from '../media/images/screenshots/Screenshot_A14.jpg'
import slide_3_img from '../media/images/screenshots/Screenshot_A17.jpg'

import chromaHubImg from '../media/images/screenshots/Screenshot_A12.jpg'
import biofeedbackImg from '../media/images/screenshots/Screenshot_A15.jpg'
import controllersImg from '../media/images/misc/controllers.png'
import logomark from '../media/images/chroma-logo-dark.png';
import { useEffect } from "react"

const _ = require('lodash')

function Home(props) {
    const rootStore = useStores()
    let history = useNavigate()
    let location = useLocation()

    useEffect(() => {
        rootStore.AuthStore.user.hydrate(rootStore.AuthStore.userId, rootStore.AuthStore.token)
    }, [])

    /* Social Auth */
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    let urlToken = urlParams.get("token")
    let urlId = urlParams.get("id")

    
    let { from } = location.state || { from: { pathname: "/dashboard" } }
    const socialLogin = (token, id) => {
        // TODO:: Replace token with actual recieved token
        rootStore.AuthStore.authenticate(id, token)
        history(from)
    }

    if(urlToken && urlId){
        // console.log(urlToken, urlId)
        socialLogin(urlToken, urlId)
    }

    return (
        <Layout noContainer="true">
            <MetaTags
                title="CHROMA | Tap into your flowstate"
            />
         
            <div className={styles.Home}>
                <div className={styles.HomeHeaderContainer}>
                    <div className={styles.LogoContainer}>
                        <img src={logoDark} alt="Microdose VR Logo" className={styles.SlideLogo} />
                        <br />
                        <Link to="/products">
                            <button className={`${styles.Button}`}>
                                <FontAwesomeIcon icon={faShoppingCart} className={styles.CartIcon} />
                                Now Available!
                            </button>
                        </Link>
                    </div>

                    <img src={logomark} className={styles.ChromaLogo} />
                    
                    <Slider 
                        autoplay
                        autoplaySpeed={12000}
                        dots
                        easing="ease"
                        infinite
                        pauseOnHover={false}
                        slidesToScroll={1}
                        slidesToShow={1}
                        speed={1000}>
                        
                        

                        {/* Slide 1 */}
                        <div>
                            <div className={styles.HomeContainer} style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.8), rgb(66, 9, 126)), url(${slide_1_img})`}}></div>
                        </div>

                        {/* Slide 2 */}
                        <div>
                            <div className={styles.HomeContainer} style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.8), rgb(66, 9, 126)), url(${slide_2_img})`}}></div>
                        </div>

                        {/* Slide 3 */}
                        <div>
                            <div className={styles.HomeContainer} style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.8), rgb(66, 9, 126)), url(${slide_3_img})`}}></div>
                        </div>

                    </Slider>
                </div>

                {/* Worlds Callout */}
                <Hero 
                    title = "Controller Input"
                    description = "Coming soon to Microdose"
                    flip = {true}
                    // button = "🧠 Learn More"
                    // buttonUrl = "https://manual.microdosevr.com/biofeedback"
                    primaryImageSource = {biofeedbackImg}
                    // primaryImageSource = "https://androidjones.com/wp-content/uploads/2010/07/Dreamcatcher.jpg"
                    altImageSource = {controllersImg}
                    removeAltImageShadow = {true}
                    backgroundPosition = "bottom center"
                    color1 = "rgba(66, 9, 126, .8)"
                    color2 = "rgba(66, 9, 126, 1)"
                    />

                

                {/* OSC Callout */}
                <Hero 
                    title = "OSC Interface"
                    description = "Control Microdose like never before"
                    button = "Learn More"
                    buttonUrl = "https://manual.microdosevr.com/osc"
                    primaryImageSource = "https://chroma-media-platform.s3-us-west-1.amazonaws.com/images/platform/misc/OSC.jpg"
                    altImageSource = "https://chroma-media-platform.s3-us-west-1.amazonaws.com/images/platform/misc/OSC_Layout.png" 
                    />

                {/* Download CHROMA */}
                <Hero 
                    title = "CHROMA Hub"
                    description = "Manage Microdose VR builds"
                    flip = {true}
                    button = "Download"
                    buttonPath = "/download"
                    primaryImageSource = {chromaHubImg}
                    altImageSource = "https://manual.microdosevr.com/chroma-logo-dark.png"
                    removeAltImageShadow = {true}
                    color1 = "rgba(66, 9, 126, .8)"
                    color2 = "rgba(66, 9, 126, 1)"
                    />
            </div>
        </Layout>
    )
}

export default Home
/* Framework */
import { types } from 'mobx-state-tree'

import * as BaseModel from "../../structure/BaseModel"

import UserMetaModel from "./UserMetaModel"
import UserAssetModel from "./UserAssetModel"

import chroma from '@chromaplatform/chromajs'

const _ = require('lodash')
const moment = require('moment')


/* Model Properties */
const props = {
    location: types.optional(types.string, ""),
    ethId: types.optional(types.string, ""),
    created: types.optional(types.string, "n/a"),
    meta: types.optional(UserMetaModel, {}),
    provider: types.optional(types.string, ""),
    username: types.optional(types.string, ""),
    verified: types.optional(types.boolean, false),
    roles: types.optional(types.array(types.string, ""), []),
    products: types.optional(types.array(types.string, ""), []),
    assets: types.optional(types.array(UserAssetModel, ""), [])
}

/* Model Actions */
const actions = (self) => ({
    hydrate(userId, token) {
        // Fetch current address from server and hydrate this model
        const query = {
            service: "users",
            id: userId,
            fields: "username ethId createdAt provider isVerified meta{assets appData products style origin profile} roles{roleAssignments}"
        }

        // test flush
        // chroma.cache.flushCache()

        chroma.get(query).then(data => {
            self.setUser(data)
        }).catch(error => {
            console.log('login err', error)
        })
    },
    setEth(address) {
        self.ethId = address
    },
    setUser(user) {
        // console.log('SET USER', user)
        // General
        self.username = _.get(user, "username") || "NA"
        self.provider = _.get(user, "provider") || "NA"
        self.created = moment(_.get(user, "createdAt", 1) * 1000).format("MM/DD/YYYY")
        self.verified = _.get(user, "isVerified")
        self.ethId = _.get(user, "ethId")
        
        // Sessions
        self.meta.appData.MDVR.sessions.setMinutesPlayed(_.get(user, "meta.appData.MDVR.sessions.minutesPlayed") || 0)

        // Origin
        const userOrigin = _.get(user, "meta.origin")
        self.meta.origin.setOrigin(_.get(userOrigin, "publisherId") || "", _.get(userOrigin, "appId") || "", _.get(userOrigin, "seedcode") || "")

        // Profile
        const userProfile = _.get(user, "meta.profile")
        self.meta.profile.setAvatar(_.get(userProfile, "avatarURL") || "https://chroma-avatars.sfo3.digitaloceanspaces.com/eye-purple.png")
        self.meta.profile.setBio(_.get(userProfile, "bio") || "")
        self.meta.profile.setYoutubeURL(_.get(userProfile, "youtubeURL") || "")
        self.meta.profile.setYoutubePlaylistId(_.get(userProfile, "youtubePlaylistId") || "")
        self.meta.profile.setTwitchURL(_.get(userProfile, "twitchURL") || "")
        self.meta.profile.setFacebookURL(_.get(userProfile, "facebookURL") || "")
        self.meta.profile.setInstagramURL(_.get(userProfile, "instagramURL") || "")

        // Colors
        const userColors = _.get(user, "meta.style.colors.core")
        self.meta.style.colors.core.setPrimaryColor(_.get(userColors, "primaryColor") || "#6f0cd8")
        self.meta.style.colors.core.setSecondaryColor(_.get(userColors, "secondaryColor") || "#757575")

        // Roles
        let newRoles = []
        const roleAssignments = _.get(user, "roles.roleAssignments")

        for (let i = 0; i < roleAssignments.length; i++) {
            const roleName = _.get(roleAssignments[i], "roleName")
            newRoles.push(roleName)
        }

        self.roles = newRoles

        // Products
        let newProducts = []
        const products = _.get(user, "meta.products", [])
        for (let j = 0; j < products.length; j++) {
            const productId = _.get(products[j], "productId")
            newProducts.push(productId)
        }

        self.products = newProducts

        // Assets
        let newAssets = []
        const assets = _.get(user, "meta.assets", [])
        
        for (let j = 0; j < assets.length; j++) {
            const assetId = assets[j]
            newAssets.push(assetId)
        }

        self.assets = newAssets
    },
    hasRole(role) {
        return self.roles.includes(role)
    },
    hasProduct(productId) {
        return self.products.includes(productId)
    }
})

/* UserModel */
const UserModel = types
  .model('UserModel')
  /* BaseModel */
  .props(BaseModel.props)
  .views(BaseModel.views)
  .actions(BaseModel.actions)
  /* Model (must come at end otherwise may be overridden) */
  .props(props)
  .actions(actions)

export default UserModel

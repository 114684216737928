/* Layout */

/* Router */
import { Link } from "react-router-dom"

/* Components */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

/* Media */
import styles from './Nav.module.css'

function NavGuest(props) {
    return (
        <div className={styles.NavGuest}>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {/* Buy Now */}
                <li className={`nav-item ${styles.BuyNowContainer}`}>
                    <Link to="/products" className={`nav-link ${styles.BuyNow}`}>Buy Now</Link>
                </li>

                {/* Blog */}
                <li className="nav-item">
                    <a href="https://microdosevr.com" className="nav-link">Blog</a>
                </li>

                {/* Resources */}
                <li className="nav-item">
                    <Link to="/resources" className="nav-link">Resources</Link>
                </li>


                {/* Download */}
                <li className="nav-item">
                    <Link to="/download" className="nav-link">Download</Link>
                </li>

                {/* Login */}
                <li className="nav-item">
                    <Link to="/login" className="nav-link">
                        Login
                        <FontAwesomeIcon style={{marginLeft: "12px"}} icon={faUser} />
                    </Link>
                </li> 
             </ul>    
        </div>  
    )
}

export default NavGuest
/* Layout */

/* Router */
import { Link } from "react-router-dom"

/* Data */
import { useStores } from "../../context/RootStoreContext"

/* Components */
import NavUserDropdown from "./NavUserDropdown"

import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'


/* Media */
import styles from './Nav.module.css'

const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: "#8131d6",
      right: 8,
      top: 2,
      border: `2px solid rgba(255, 255, 255, 0.637)`,
      padding: '0 4px',
    },
  }))(Badge)

function NavAuthenticated(props) {
    const rootStore = useStores()

    return (
        <div className={styles.NavAuthenticated}>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {/* Buy Now */}
                <li className={`nav-item ${styles.BuyNowContainer}`}>
                    <Link to="/products" className={`nav-link ${styles.BuyNow}`}>Buy Now</Link>
                </li>

                {/* Blog */}
                <li className="nav-item">
                    <a href="https://microdosevr.com" className="nav-link">Blog</a>
                </li>

                {/* Resources */}
                <li className="nav-item">
                    <Link to="/resources" className="nav-link">Resources</Link>
                </li>


                {/* Download */}
                <li className="nav-item">
                    <Link to="/download" className="nav-link">Download</Link>
                </li>

                {/* Cart */}
                {rootStore.Cart.products.length > 0 &&
                    <li className="nav-item">
                        <Link to="/products/checkout" className={styles.CartButton}>
                            <StyledBadge className={styles.CartBadge} badgeContent={rootStore.Cart.products.length} color="secondary" overlap="rectangular">
                                <FontAwesomeIcon className={styles.CheckoutIcon} icon={faShoppingCart} />
                            </StyledBadge>
                            <p>Checkout</p>
                        </Link> 
                    </li>
                }

                {/* User Dropdown Menu - Authenticated */}
                <NavUserDropdown />
            </ul>
        </div>  
    )
}

export default NavAuthenticated
import { useParams } from "react-router-dom"
import _ from 'lodash'

/* Components */
import Hero from "components/media/Hero"
import Layout from "components/layout/Layout"
import { Helmet } from 'react-helmet-async'
import templates from "CHROMA-React/config/templates"

/* Media */
import styles from './SingleDefault.module.css'
import { useEffect, useState } from 'react'
import chroma from 'CHROMA-React/code/chroma'

const SingleView = (props) => {
    const [viewData, setViewData] = useState([])
    
    let { id, service } = useParams();

    useEffect(() => {
        /* Create Query */
        let query = {
            service: service,
            id: id,
            fields: "_id type subtype meta copy children tokenId"
        }
        
		/* Get initial abilities from chroma */
		const makeQuery = async () => {    
            const chromaRequest = await chroma.get(query)
            setViewData(chromaRequest)
            // getNextRequest()
		}
        

		if(query !== ""){
            makeQuery()
        }

        return({})
	}, [])

    const getTemplate = (item) => {
        const type = _.get(item, "type")
        
        // TEMP RETURN DEFAULT FOR ALL
        let Template
        // Template = _.get(templates, `default`)
        // return <Template key={i} item={item} />
        // return Template

        if(type){
            Template = _.get(templates, `${service}.${type}-single`)
        } else {
            // no type present, use default
            Template = _.get(templates, `${service}.default-single`)
        }
        if(Template){
            return <Template item={item} service={service} id={id} />
        } else {
            Template = _.get(templates, `default-single`)
            return <Template item={item} service={service} id={id} />
        }

    }
    
    return (
        <div>
            <Layout noContainer={true}>
                <Helmet>
                    <title>{`CHROMA | ${service}`}</title>
                </Helmet>
                
                <div className="container">
                    <div className={styles.Container}>
                        <div className="row">
                            {/* Right Column */}
                            <div className="col-lg-1"></div>
                            <div className="col-lg-10">
                                <div className={styles.SingleContainer}>
                                    {viewData && getTemplate(viewData)}
                                </div>
                            </div>
                            <div className="col-lg-1"></div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default SingleView
/* <Accordian /> */

import { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faEye, faLaptop, faServer } from '@fortawesome/free-solid-svg-icons'

/* Media */
import styles from './Accordian.module.css'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}))

function Accordian(props) {
    const classes = useStyles()
    const [expanded, setExpanded] = useState('panel1')

    const handleChange = (panel) => (event, isExpanded) => {
        // setExpanded(isExpanded ? panel : false)
    }

    return (
        <div className={styles.AccordianContainer}>
            {/* Accordian */}
            <Accordion className={styles.AccordianRoot} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                {/* Summary */}
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <p>Minimum Requirements</p>
                </AccordionSummary>
                {/* Details */}
                <AccordionDetails>
                    <div className="row">
                        <div className={`${styles.DetailsContainer} col-sm`}>
                            <FontAwesomeIcon className={styles.DetailsIcon} icon={faLaptop} />
                            <h3 className={styles.DetailsHeader}>CPU</h3>
                            <p className={styles.DetailsContent}>
                                Intel i5, i7 or greater<br />
                                AMD Ryzen 5 1600 or greater
                            </p>
                        </div>
                        <div className={`${styles.DetailsContainer} col-sm`}>
                            <FontAwesomeIcon className={styles.DetailsIcon} icon={faDesktop} />
                            <h3 className={styles.DetailsHeader}>GPU</h3>
                            <p className={styles.DetailsContent}>
                                Nvidia GTX1060 or greater<br />
                                AMD Radeon RX 580 (8gb) or greater
                            </p>
                        </div>
                        {/* <div className={`${styles.DetailsContainer} col-sm`}>
                            <FontAwesomeIcon className={styles.DetailsIcon} icon={faMicrochip} />
                            <h3 className={styles.DetailsHeader}>RAM</h3>
                            <p className={styles.DetailsContent}>16gb+</p>
                        </div> */}
                        <div className={`${styles.DetailsContainer} col-sm`}>
                            <FontAwesomeIcon className={styles.DetailsIcon} icon={faEye} />
                            <h3 className={styles.DetailsHeader}>VR</h3>
                            <p className={styles.DetailsContent}>
                                Oculus Rift/Rift S<br />
                                Quest/Quest2 w/ Link<br />
                                HTC Vive/Vive Pro<br />
                                Valve Index<br />
                                Windows MR
                                </p>
                        </div>
                        <div className={`${styles.DetailsContainer} col-sm`}>
                            <FontAwesomeIcon className={styles.DetailsIcon} icon={faServer} />
                            <h3 className={styles.DetailsHeader}>OS</h3>
                            <p className={styles.DetailsContent}>Windows 10</p>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default Accordian
/* Header */

/* Components */
import { useStores } from "context/RootStoreContext"
import { observer } from "mobx-react"

import { Avatar, Image } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock, faSeedling, faUser } from '@fortawesome/free-solid-svg-icons'

import _ from "lodash"

/* Media */
import styles from './users-info.module.css'
import eyePurple from '../../media/images/eye-purple.png'

function UsersInfo(props) {
    const rootStore = useStores()
    const username = _.get(rootStore, "AuthStore.user.username")
    const avatarURL = _.get(rootStore, "AuthStore.user.meta.profile.avatar_url")
    const userId = _.get(rootStore, "AuthStore.userId")
    const origin = _.get(rootStore, "AuthStore.user.meta.origin", []).toJSON()
    const seedcode = _.get(origin, "seedcode")
    const minutesPlayed = _.get(rootStore, "AuthStore.user.meta.appData.MDVR.sessions.minutesPlayed", 0)

    return (
        <div className={`${styles.container} ${props.className}`}>
            {/* Avatar */}
            <div className={styles.avatarContainer}>
                <img src={avatarURL} className={styles.avatar} />
            </div>
            
            <h3 className={styles.username}>{username}</h3>
            <p className={styles.userid}>{userId}</p>

            {/* Member Since */}
            <p className={styles.membersince}>
                <FontAwesomeIcon icon={faCalendar} className={styles.joinedIcon} /> 
                Joined <em><strong>2 years ago</strong></em>
            </p>

            {/* Minutes Played */}
            {minutesPlayed > 0 && 
                <p className={styles.seedcode}>
                    <FontAwesomeIcon icon={faClock} className={styles.joinedIcon} /> 
                    <em><strong>{minutesPlayed}</strong></em> minutes played
                </p>
            }

            {/* Seedcode */}
            {seedcode && 
                <p className={styles.seedcode}>
                    <FontAwesomeIcon icon={faSeedling} className={styles.joinedIcon} /> 
                    Seedcode <em><strong>{seedcode}</strong></em>
                </p>
            }
        </div>
    )
}

export default observer(UsersInfo)
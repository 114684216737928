/* UserOriginModel */

import { types } from 'mobx-state-tree'

/* Model Properties */
const props = {
    avatar_url: types.optional(types.string, ""),
    bio: types.optional(types.string, ""),
    youtubeURL: types.optional(types.string, ""),
    twitchURL: types.optional(types.string, ""),
    facebookURL: types.optional(types.string, ""),
    instagramURL: types.optional(types.string, ""),
    youtubePlaylistId: types.optional(types.string, "")
}

/* Model Actions */
const actions = (self) => ({
    setAvatar(url) { self.avatar_url = url },
    setBio(bio) { self.bio = bio },
    setYoutubeURL(url) { self.youtubeURL = url },
    setTwitchURL(url) { self.twitchURL = url },
    setFacebookURL(url) { self.facebookURL = url },
    setInstagramURL(url) { self.instagramURL = url },
    setYoutubePlaylistId(id) { self.youtubePlaylistId = id }
})

const UserProfileModel = types
  .model('UserProfileModel')
  .props(props)
  .actions(actions)

  export default UserProfileModel
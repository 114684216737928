/* Router */
import {
    BrowserRouter as Router,
    Routes,
    Navigate,
    Route
} from "react-router-dom"

import { ToastProvider } from 'react-toast-notifications'

/* Views */
import AssetScreenshot from "./views/AssetScreenshot"
import CheckoutDev from "./views/CheckoutDev"

import Dashboard from "./views/Dashboard"
import Download from "./views/Download"
import GeneralLayout from "./components/layout/General"
import Home from "./views/Home"
import Login from "./views/Login"
import Lost from "./views/Lost"
import Products from "./views/Products"
import Profile from "./views/Profile"
import Purchase from "./views/Purchase"
import Resources from "./views/Resources"
import Transaction from "./views/Transaction"
import Worlds from "./views/Worlds"

/* Components */
import { useStores } from "context/RootStoreContext"

import PrivateRoute from "./components/utilities/PrivateRoute"
import CryptoDash from "components/dashboard/CryptoDash"
import AdminDash from "components/dashboard/AdminDash"
import FormEditDashboard from "components/dashboard/FormEditDashboard"
import OverviewDash from "components/dashboard/OverviewDash"
import Layout from "components/layout/Layout"
import AdminUserDash from "components/admin/AdminUserDash"
import QueryBuilderView from "views/QueryBuilderView"
import SingleView from "CHROMA-React/components/views/Single"
import Overview from "CHROMA-React/components/views/Overview"

function RoutesView(props) {
    const rootStore = useStores()

    return (
        <Router>
            <Routes>
                {/* DEV */}
                {/* <Route path="/dev" component={Dev} exact /> */}
                {/*<Route path="/checkoutdev" element={<CheckoutDev />} exact />*/}

                {/* Query Builder */}
                <Route exact path='/chromaql' element={rootStore.AuthStore.user.hasRole('appDeveloper') ? <QueryBuilderView /> : <Navigate to="/login" />}/>
                
                {/* Login */}
                <Route path="/login" element={<Login />} exact />

                {/* Dashboard */}
                <Route exact path='/dashboard' element={rootStore.AuthStore.authenticated ? <Dashboard /> : <Navigate to="/login" />}/>
                
                {/* Settings */}
                <Route exact path='/settings' element={rootStore.AuthStore.authenticated ? <GeneralLayout><FormEditDashboard /></GeneralLayout> : <Navigate to="/login" />}/>

                {/* NFTs */}
                <Route exact path='/nfts' element={rootStore.AuthStore.authenticated ? <GeneralLayout><CryptoDash /></GeneralLayout> : <Navigate to="/login" />}/>

                {/* Admin */}
                <Route exact path='/admin' element={rootStore.AuthStore.user.hasRole('appDeveloper') ? <Layout><AdminDash /></Layout> : <Navigate to="/login" />}/>

                <Route exact path='/admin/users' element={rootStore.AuthStore.user.hasRole('appDeveloper') ? <Layout><AdminUserDash /></Layout> : <Navigate to="/login" />}/>

                {/* Worlds */}
                <Route path="/worlds" element={<Worlds />} exact />

                {/* Resources */}
                <Route path="/resources" element={<Resources />} exact />

                {/* Download */}
                <Route path="/download" element={<Download />} exact />

                {/* Products */}
                <Route path="/products" element={<Products />} exact />
                <Route exact path='/products/checkout' element={rootStore.AuthStore.authenticated ? <Purchase/> : <Navigate to="/login" />}/>
                {/*<PrivateRoute path="/products/checkout" element={<Purchase />} exact />*/}

                {/* Transactions */}
                <Route path="/tx/:transactionId" element={<Transaction />} exact />
                {/*<Route path="/transactions/:transactionId" element={<Transaction />} exact />*/}

                {/* Reload Utility */}
                <Route path="/reload" element={<Dashboard />} exact />

                {/* Legacy Link Redirects */}
                <Route path="/download-chroma" element={<Download />} exact />
                <Route path="/osc" element={<Resources />} exact />

                <Route path="/404" element={<Lost />} />

                {/* Profile */}
                <Route path="/:username" element={<Profile />} exact />

                {/* Single Views */}
                <Route path="/:service/:id" element={<SingleView />} exact />

                {/* Services View */}
                {/*<Route path="/assets" element={<Overview service="assets" header="🖼️ assets" />} exact />*/}

                {/* Root */}
                <Route path="/" element={<Home />} exact />

                {/* 404 */}
                <Route element={<Lost />} />

            </Routes>
        </Router>
    )
  }
  
  export default RoutesView
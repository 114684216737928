import React from "react"
import ReactDOM from 'react-dom'

/* Components */
import App from './App'

/* Performance */
// import reportWebVitals from './reportWebVitals'

/* Data */
import { setupRootStore } from "./data/setupRootStore"

/* Media */
import './index.css'

// Setup RootStore
let rootStore

// Setup chroma_endpoint
window.localStorage.setItem('chroma_endpoint', 'https://api.chroma.vision')
// window.localStorage.setItem('chroma_endpoint', 'https://devapi.chroma.vision')
// window.localStorage.setItem('chroma_endpoint', 'http://localhost:8080')



setupRootStore().then((data) => {
  rootStore = data
  if (!rootStore) {
    return null
  }

  // Original
  ReactDOM.render(
    <React.StrictMode>
      <App rootStore={rootStore} />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // // React-Snap Update
  // const rootElement = document.getElementById("root");

  // if (rootElement.hasChildNodes()) {
  //   ReactDOM.hydrate(<App rootStore={rootStore} />, rootElement);
  // } else {
  //   ReactDOM.render(<App rootStore={rootStore} />, rootElement);
  // }
})



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)

/* Framework */
import { types } from 'mobx-state-tree'

/* Stores */
import AuthStore from './AuthStore'
import SettingsStore from './SettingsStore'

/* Models */
import * as BaseModel from '../structure/BaseModel'
import CartModel from 'data/models/Cart/CartModel'


/* RootStore Properties */
const props = {
    AuthStore: types.optional(AuthStore, {}),
    SettingsStore: types.optional(SettingsStore, {}),

    Cart: types.optional(CartModel, {}),

    initialized: types.optional(types.boolean, true)
}

/* RootStore Actions */
const actions = (self) => ({
    reset() {}
})

/* RootStore Model */
const RootStore = types
  .model('RootStore')
  .props(props)
  .actions(actions)
  /* BaseModel */
  .props(BaseModel.props)
  .views(BaseModel.views)
  .actions(BaseModel.actions)


export default RootStore



/* <AdminDash /> */

import { useEffect, useState } from "react"

/* Data */
import chroma from '@chromaplatform/chromajs'

/* Components */
import ChromaList from "CHROMA-React/components/chroma-list"


/* Media */
import styles from './AdminUserDash.module.css'
import SubNav from "components/nav/SubNav"

const _ = require('lodash')
const moment = require('moment')

function AdminUserDash(props) {

    const chromaQLUsers = {
      service: "users",
      fields: "_id email username meta createdAt roles isVerified active ethId"
    }

    return(
        <div className={styles.AdminDashContainer}>

          {/* Users */}
          <div className={styles.Section}>
            <h1>All Users</h1>
            <ChromaList query={chromaQLUsers} />
          </div>
        </div>
    )
}

export default AdminUserDash
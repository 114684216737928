/* mobx-state-tree setup rootStore */
import { getSnapshot, onSnapshot } from "mobx-state-tree"

/* Stores */
import RootStore from "./stores/RootStore"

/* Constants */
const ROOT_STORE_KEY = "chroma-frontend-root-store"
const RESET_LOCAL_DATA = false // True to reset all local data

const rootStoreDefaults = {
    address: "/",
    namespace: "root",
    id: "root"
}

export async function setupRootStore() {
    let rootStore

    if(RESET_LOCAL_DATA){
        // Reset
        console.log("> Initializing CHROMA Data Store")

        rootStore = await initializeLocalStoreData()
        storeSnapshot(rootStore)
    } else {
        rootStore = await getLocalData(ROOT_STORE_KEY)
        console.log("> CHROMA Data Store Hydrated", getSnapshot(rootStore))
    }

    /* Store local version on snapshot */
    onSnapshot(rootStore, (rootStore) => {
        storeSnapshot(rootStore)
        console.log("> CHROMA Snapshot", rootStore)
    })

    return rootStore
}


/* Store snapshots to localstorage */
export async function storeSnapshot(snapshot) {
    try {
        // await AsyncStorage.setItem(ROOT_STORE_KEY, JSON.stringify(snapshot))
        localStorage.setItem(ROOT_STORE_KEY, JSON.stringify(snapshot))
        return true
    } catch {
        return false
    }
}


async function initializeLocalStoreData() {
    const rootStore = RootStore.create(rootStoreDefaults, rootStoreDefaults)
    return rootStore
}


/* Get Local Data from localStorage */
async function getLocalData(STORE_KEY) {
    let localData
    try {
        // Get local data
        localData = (localStorage.getItem(STORE_KEY)) || {}
        localData = RootStore.create(JSON.parse(localData))
    } catch (e) {
        // If data cannot be found, initialize
        localData = initializeLocalStoreData()
    }

    return localData
}
/* CartModel */

import { types } from 'mobx-state-tree'

/* Model Properties */
const props = {
    products: types.optional(types.array(types.string, ""), [])
}

/* Model Actions */
const actions = (self) => ({
    addProduct(productId) {
        if(!self.hasProduct(productId)) self.products.push(productId)
    },
    setProducts(productIds){
        self.products = productIds
    },
    removeProduct(productId) {
        self.products = self.products.filter(e => e !== productId)
    },
    hasProduct(productId) {
        return self.products.includes(productId)
    },
    clearCart(){
        console.log('CartModel.clearCart()')
        self.products = []
    }
})

const CartModel = types
  .model('CartModel')
  .props(props)
  .actions(actions)

  export default CartModel
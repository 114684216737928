/* <ProfileOverview /> */

/* Components */
import VideoEmbed from 'components/media/VideoEmbed'

import CircularProgress from '@material-ui/core/CircularProgress'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faBurn, faMountain, faWind } from '@fortawesome/free-solid-svg-icons'

import styles from './ProfileOverview.module.css'

const _ = require('lodash')

function ProfileOverview(props) {
    return(
        <div>
            <h3 style={{textAlign:"center", margin: "0 0 48px 0", opacity: .4}}>
                <FontAwesomeIcon icon={faAddressCard} style={{marginRight: 12, opacity: .4}} />
                User profiles activating soon
            </h3>
            {/* Heat Flow Mass */}
            <div className="row" style={{display: "none"}}>
                <div className="col-sm">
                    {/* Hear */}
                    <div className={`${styles.Card} card`}>
                        <div className="card-body" style={{padding: 0}}>
                            <div className={`${styles.Container} ${styles.Heat}`}>
                                <FontAwesomeIcon icon={faBurn} className={styles.Icons} />
                                <h2 className={styles.Title}>Heat</h2>
                                <h2 className={styles.Amount}>0</h2>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-sm">
                    {/* Mass */}
                    <div className={`${styles.Card} card`}>
                        <div className="card-body" style={{padding: 0}}>
                            <div className={`${styles.Container} ${styles.Mass}`}>
                                <FontAwesomeIcon icon={faMountain} className={styles.Icons} />
                                <h2 className={styles.Title}>Mass</h2>
                                <h2 className={styles.Amount}>0</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm">
                    {/* Flow */}
                    <div className={`${styles.Card} card`}>
                        <div className="card-body" style={{padding: 0}}>
                            <div className={`${styles.Container} ${styles.Flow}`}>
                                <FontAwesomeIcon icon={faWind} className={styles.Icons} />
                                <h2 className={styles.Title}>Flow</h2>
                                <h2 className={styles.Amount}>0</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Playlist */}
            <div>
                <VideoEmbed title="Streams" playlistid={_.get(props, 'profile.youtubePlaylistId')} />
            </div>

            {/* Temp */}
            {/* <div className="row">
                <div style={{padding: 64, textAlign: "center"}}><CircularProgress disableShrink /></div>
            </div> */}
        </div>
    )
}

export default ProfileOverview
/* <CopyableText /> */

import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

import { withStyles } from "@material-ui/core/styles"



const copyToClipboard = (text) => {
    if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text) 

    } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea")
        textarea.textContent = text
        textarea.style.position = "fixed"  // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea)
        textarea.select()
        try {
            return document.execCommand("copy")  // Security exception may be thrown by some browsers.
        } catch (ex) {
            console.warn("Copy to clipboard failed.", ex)
            return false
        } finally {
            document.body.removeChild(textarea)
        }
    }
}  

function CopyableText(props) {
    const BlueTooltip = withStyles({
        tooltip: {
          color: props.color || "#fff",
          backgroundColor: props.background || "#333",
          fontFamily: "Roboto",
          fontSize: "14px",
          padding: props.padding || "12px 16px"
        }
    })(Tooltip)

    if(!props.text) return null
    return(
        <BlueTooltip title={props.tooltip || "Click to copy"} enterNextDelay={3} TransitionComponent={Zoom} placement={props.position || "top"}>
            <span style={{cursor: "pointer"}} onClick={() => copyToClipboard(props.copytext || props.text)}>
                {props.text}
            </span>
        </BlueTooltip>
    )
}

export default CopyableText
import { useEffect } from "react"

/* Router */
import {
    useNavigate,
    useLocation
  } from "react-router-dom"
import Layout from "../components/layout/Layout"

import { useStores } from "../context/RootStoreContext"
import { useToasts } from 'react-toast-notifications'

/* Components */
import { Auth } from '@chromaplatform/chroma-react-ui'

/* Media */
import styles from './Login.module.css'

import login_img from '../media/images/screenshots/Screenshot_A5.png'

const _ = require('lodash')

function Login(props) {
    let navigate = useNavigate()
    let location = useLocation()

    const { addToast } = useToasts()

    let { from } = location.state || { from: { pathname: "/dashboard" } }
    const rootStore = useStores()


    useEffect(() => {
        const handleAuth = e => {
            const result = e.detail
            
            // Custom implimentation here
            if(result.token !== undefined) {
                rootStore.AuthStore.authenticate(result.id, result.token)
                setTimeout(() => {
                    console.log('navigate to /dashboard')
                    navigate("/dashboard", { replace: true })
                    navigate(0)
                }, 400)
            } else {
                console.log('>> CHROMA: Authentication Error')
                console.log(result.error)
            }
        }

        const handleRegister = e => {
            const result = e.detail
            
            const notificationConfig = { appearance: 'success' }
            addToast("Account created. Please verify your email and login", notificationConfig)
        }
        // Setup Events
        global.addEventListener('/user/authenticated', handleAuth)
        global.addEventListener('/user/registered', handleRegister)

        // Triggers on teardown - Remove Events
        return () => {
            global.removeEventListener('/user/authenticated', handleAuth)
            global.removeEventListener('/user/registered', handleRegister)
        }
    }, [rootStore, navigate, from])


    return (
        <Layout noContainer={true}>
            <div className={styles.AuthPage} style={{backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.8), rgba(66, 9, 126, .9)), url(${login_img})`}}>
                <div className={styles.AuthContainer}>
                    <h3>Login or Register</h3>
                    <Auth source="https://chroma.vision/" />
                </div>
            </div>
        </Layout>
    )
}

export default Login
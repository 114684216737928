/* Template Imports */
import ProductListItem from "../components/list-items/product"
import TransactionListItem from "../components/list-items/transaction"
import AssetListItem from "../components/list-items/asset"
import AssetNFTListItem from "../components/list-items/asset-nft"
import UserListItem from "CHROMA-React/components/list-items/user"
import DefaultListItem from "CHROMA-React/components/list-items/default"
import SingleDefaultView from "CHROMA-React/components/views/SingleDefault"
import AssetPOAPListItem from "CHROMA-React/components/list-items/asset-poap"

/* Templates */
const templates = {
    default: DefaultListItem,
    "default-single": SingleDefaultView,
    apps: {},
    assets: {
        "default-list": AssetListItem,
        "default-single": "",
        "nft-list": AssetNFTListItem,
        "nft-single": "",
        "poap-list": AssetPOAPListItem,
        "nft-single": "",
    },
    posts: {},
    products: {
        "default-list": ProductListItem,
        "default-single": "",
    },
    sessions: {},
    transactions: {
        // "default-list": TransactionListItem,
        "default-single": "",
    },
    userappdata: {},
    users: {
        "default-list": UserListItem,
        "default-single": "",
    },
    supplycode: {},
    supplytoken: {},
}

export default templates
/* Dashboard */

/* Core */
import { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

/* Data */
import { useStores } from "../context/RootStoreContext"

/* Components */
import CopyableText from '../components/utilities/CopyableText'
import Layout from "../components/layout/Layout"
import MetaTags from "../components/utilities/MetaTags"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faShoppingCart, faDollarSign, faMoneyCheck, faCheckCircle, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { faBitcoin } from '@fortawesome/free-brands-svg-icons'

import { Icon } from '@iconify/react'
import btcIcon from '@iconify/icons-cryptocurrency/btc'
import ltcIcon from '@iconify/icons-cryptocurrency/ltc'
import ethIcon from '@iconify/icons-cryptocurrency/eth'
import usdtIcon from '@iconify/icons-cryptocurrency/usdt'
import trxIcon from '@iconify/icons-cryptocurrency/trx'
import xmrIcon from '@iconify/icons-cryptocurrency/xmr'

import Accordian from 'components/data/Accordian'

/* Media */
import styles from './Products.module.css'

const _ = require('lodash')


function Products(props) {
    const rootStore = useStores()
    const history = useNavigate()

    /* Setup */
    const useStyles = makeStyles((theme) => ({
        '@global': {
          ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
          },
        },
        appBar: {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        toolbar: {
          flexWrap: 'wrap',
        },
        toolbarTitle: {
          flexGrow: 1,
        },
        link: {
          margin: theme.spacing(1, 1.5),
        },
        heroContent: {
          padding: theme.spacing(8, 0, 6),
        },
        cardHeader: {
          backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
        },
        cardPricing: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'baseline',
          marginBottom: '18px',
          marginTop: '18px',
        },
        footer: {
          borderTop: `1px solid ${theme.palette.divider}`,
          marginTop: theme.spacing(8),
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
          },
        },
    }))

    const gotoCheckout = (productId) => {
        history(`/products/checkout`)
    }

    const addProduct = (productId) => {
        if(!rootStore.Cart.hasProduct(productId)){
            // Add to cart
            rootStore.Cart.addProduct(productId)
        }

        // Wait for a moment and send to checkout
        setTimeout(() => {
            gotoCheckout()
        }, 200)
    }

    const tiers = [
        {
          title: 'VJ Mode',
          price: '.002',
          productId: '603337f0f3988619c076f559',
          description: [
              'Public performance license', 
              'VJ Asset Pack', 
              'Removes watermark'],
          buttonText: 'Add on',
          buttonVariant: 'outlined',
          bottomText: 'Coming Soon',
          buttonVariant: 'contained'
        },
        {
            title: 'Microdose VR',
            subheader: 'Full Release',
            productId: '62cc47508b3d1d3f054f14c5',
            price: '50',
            description: [
              'Explore the many worlds of the Microverse',
              'All New Gamepad Mode (No VR required)',
              'Regular Updates with new worlds & brushes',
              '360º output for domes & planetariums',
              'Access to experimental Beta build',
              'Includes future updates',
              'Much more...',
            ],
            buttonText: 'Add To Cart',
            buttonVariant: 'contained'
          },
        /*{
          title: 'Microdose VR',
          subheader: 'Beta release',
          productId: '5fb3facc802b3710741fe9dd',
          price: '100',
          description: [
            'Explore the many worlds of the Microverse',
            'Regular Updates with new worlds & brushess',
            '360º output for domes & planetariums',
            'Includes future beta updates',
            'VJ Mode & Toolkit',
            'Much more...',
          ],
          buttonText: 'Add To Cart',
          buttonVariant: 'contained'
        },*/
        {
          title: 'Chromadome',
          price: '.002',
          productId: '6033380df3988619c076f55a',
          description: [
            '360º output for streams',
            'Fulldome output for planetariums',
            'Dome control OSC interface',
          ],
          buttonText: 'Add on',
          buttonVariant: 'outlined',
          bottomText: 'Coming Soon',
          buttonVariant: 'contained'
        },
    ]

    const classes = useStyles()

    return (
        <Layout noContainer="true">
            <MetaTags
                title="CHROMA | Products"
            />
            <div className={styles.ProductsContainer} dataproductscount={rootStore.Cart.products.length}>
                
                {/* Hero unit */}
                <div className={`${styles.ProductsHero} container`} >
                    <h2 className={styles.ProductsHeroHeader}>
                        Products
                    </h2>
                    <p className={styles.ProductsHeroText}>
                        Microdose is now available to everyone.
                    </p>
                </div>


                
                {/* Products Content */}
                <div className={`${styles.ProductsContent} container`}>
                    {/* <div className={styles.AlphaAlert}>
                        <FontAwesomeIcon className={styles.WarningIcon} icon={faUserAstronaut} />
                        Purchasing products is temporarily restricted to our Alpha test group members.
                    </div> */}

                    {/* Prices */}
                    <Grid className={styles.Prices} container spacing={5} alignItems="flex-end">
                        {tiers.map((tier) => (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={tier.title} xs={12} sm={12} md={4}>
                                <Card className={tier.title === 'Microdose VR' ? styles.PriceCardFeature : styles.PriceCard}>
                                    <CardHeader
                                        title={<h2 className={styles.cardTitle}>{tier.title}</h2>}
                                        subheader={<h3 className={styles.cardSubheader}>{tier.subheader}</h3>}
                                        titleTypographyProps={{ align: 'center', fontWeight: "600" }}
                                        subheaderTypographyProps={{ align: 'center' }}
                                        className={styles.cardHeader}
                                    />
                                    
                                    <CardContent>
                                        <div className={classes.cardPricing}>
                                            <h3 className={styles.price}>
                                                {tier.price}
                                            </h3>
                                            <Typography variant="h4" color="textSecondary">
                                                <FontAwesomeIcon className={styles.BitcoinIcon} icon={faDollarSign} />
                                            </Typography>
                                        </div>
                                        <ul className={styles.ProductFeaturesContainer}>
                                            {tier.description.map((line) => (
                                                <Typography className={styles.ProductFeature} component="li" variant="subtitle1" align="center" key={line}>
                                                    ✅  &nbsp; {line}
                                                </Typography>
                                            ))}
                                        </ul>
                                    </CardContent>
                                    <CardActions>
                                        <div style={{textAlign: "center", width: "100%"}}>
                                            {/* Does not have product, show button */}
                                            {!rootStore.AuthStore.user.hasProduct(tier.productId) &&
                                                <Button onClick={() => {addProduct(tier.productId)}} className={`${styles.Button}`} variant={tier.buttonVariant} color="primary">
                                                    Buy Now
                                                </Button>
                                            }

                                            {/* Has product, show in library button */}
                                            {rootStore.AuthStore.user.hasProduct(tier.productId) &&
                                                <div>
                                                    <Button onClick={() => {addProduct(tier.productId)}} className={`${styles.ButtonInLibrary}`} color="primary">
                                                        <FontAwesomeIcon className={styles.CheckoutIcon} icon={faCheckCircle} /> In Library
                                                    </Button>
                                                    <br />
                                                    <Link to="/download" style={{marginBottom:"36px", display: "inline-block"}}>
                                                        <FontAwesomeIcon className={styles.CheckoutIcon} icon={faCloudDownloadAlt} />
                                                        Download the CHROMA Hub to get started
                                                    </Link> 
                                                </div>
                                            }
                                        </div>
                                    </CardActions>
                                </Card>
                                {tier.bottomText ? <Typography component="span" className={styles.BottomText}>{tier.bottomText}</Typography> : ""}
                            </Grid>
                        ))}
                    </Grid>

                    {/*rootStore.Cart.products.length > 0 &&
                        <div className={styles.CheckoutButtonContainer}>
                            <Link to="/products/checkout" className={styles.CheckoutButton}>
                                <FontAwesomeIcon className={styles.CheckoutIcon} icon={faShoppingCart} />
                                Checkout
                            </Link> 
                        </div>
                    */}

                    {/* Info */}
                    <div className={styles.InfoContainer}>
                        <Accordian />
                    </div>

                    {/* Accepted Currencies */}
                    <div className={styles.CurrenciesContainer}>
                        <div className={`${styles.CurrencyHeader} row`}>
                            <h2 className={styles.CurrencyTitle}>We currently are only accepting credit cards</h2>
                            <p className={styles.CurrencyText}>
                                
                            </p>
                        </div>
                        <div className="row">
                            <div className={`${styles.CurrencyIconContainer} col`}>
                                <FontAwesomeIcon icon={faCreditCard} className={styles.CurrencyIconMystery} />
                            </div>
                            {/*<div className={`${styles.CurrencyIconContainer} col`}>
                                <Icon icon={xmrIcon} className={styles.CurrencyIcon} />
                            </div>
                            <div className={`${styles.CurrencyIconContainer} col`}>
                                <Icon icon={btcIcon} className={styles.CurrencyIcon} />
                            </div>
                            <div className={`${styles.CurrencyIconContainer} col`}>
                                <Icon icon={ethIcon} className={styles.CurrencyIcon} />
                            </div>
                            <div className={`${styles.CurrencyIconContainer} col`}>
                                <Icon icon={ltcIcon} className={styles.CurrencyIcon} />
                            </div>
                            <div className={`${styles.CurrencyIconContainer} col`}>
                                <Icon icon={trxIcon} className={styles.CurrencyIcon} />
                            </div>
                            <div className={`${styles.CurrencyIconContainer} col`}>
                                <Icon icon={usdtIcon} className={styles.CurrencyIcon} />
                            </div>*/}
                            {/* 
                            <div className={`${styles.CurrencyIconContainer} col`}>
                                <Icon icon={dogeIcon} className={styles.CurrencyIcon} />
                            </div> */}
                        </div>
                    </div>
                    

                    {/* Support */}
                    <div className={styles.SupportContainer}>
                        <div className="row">
                            <div className={`${styles.SupportInfo} col-12 col-md-9`}>
                                <h2 className={styles.SupportTitle}>Support Development</h2>
                                <p className={styles.SupportText}>Believe in Microdose as much as we do? Help us continue to develop new features and content.</p>
                            </div>
                            <div className="col-12 col-md-3">
                                <a className={`${styles.SupportButton} btn`} role="button">
                                    <FontAwesomeIcon icon={faBitcoin} className={styles.SupportIcon} />
                                    <CopyableText text="Donate" copytext="1JfzAWxAHef1V3Lsw2ewW8bbypXJVv9gBC" tooltip="Click to copy our BTC address" position="bottom" color="#333" background="#fff" />
                                </a>
                            </div>
                        </div>
                    </div>
            
                    
                </div>

            </div>
        </Layout>
    )
}

export default observer(Products)
/* <QueryBuilder /> */

/* Data */
import { useEffect, useState } from "react"
import { useStores } from "../../context/RootStoreContext"

import QuerySelector from "./components/QuerySelector"
import QueryPreview from "./components/QueryPreview"
import ServiceSelector from "./components/ServiceSelector"

import queryConfig from "./config/query-config";

/* Components */

/* Media */
import styles from './QueryBuilder.module.css'
import ChromaList from "CHROMA-React/components/chroma-list"
import { Link } from "react-router-dom"


const _ = require('lodash')


function QueryBuilder(props) {
    /* Setup State */
    const rootStore = useStores()
    const [service, setService] = useState(_.get(props, "service") || _.get(props, "chromaQL.service"))
    const [query, setQuery] = useState(_.get(props, "query", {}))
    const [chromaQL, setChromaQL] = useState({query: query})

    /* Sub State Management */
    const [count, setCount] = useState()

    /* Get Props */
    const header = _.get(props, "header")
    // const headerUrl = `/${service}/`

    const incomingQuery = _.get(props, "chromaQL")
    const limit = _.get(props, "limit")

    const preview = _.get(props, "preview", false) // Set default here
    const queryable = _.get(props, "queryable", false) // Set default here
    const selectService = _.get(props, "selectService") // Set default here (no default currently)

    // If any of the query builder controls are not visible, hide whole section
    const showBuilder = (preview || queryable || selectService) ? true : false

    /* Make initial request */
    useEffect(() => {
        // Get Fields
        const fields = _.get(queryConfig, `${service}.fields`)

        let newChromaQL
        if(incomingQuery){
            // Use incoming chromaQL (query param)
            newChromaQL = incomingQuery
        } else {
            // Create Initial Query
            newChromaQL = {
                service: service,
                query: query,
                fields: fields
            }
        }

        // Add limit to query
        if(limit){
            newChromaQL.query["$limit"] = limit
        }

        setChromaQL(newChromaQL)
     }, [incomingQuery, service, query, limit])
    
    return(
        <div className={`${props.className}`}>
            {header &&
                <h2 className={styles.header}>
                    {header}
                    {/*count &&
                        <span>({count})</span>
                    */}
                </h2>
            }
            {showBuilder &&
                <div className={`${styles.QueryBuilder}`}>
                    {/* Service Selector */}
                    {selectService &&
                        <ServiceSelector service={service} callback={(e)=>{setService(e)}} />
                    }

                    {/* Query Selector */}
                    {queryable && 
                        <QuerySelector limit={limit} service={service} query={_.get(props, "query", {})} callback={(query)=>{setQuery(query)}} />
                    }

                    {/* chromaQL Preview */}
                    {preview && 
                        <QueryPreview query={chromaQL} />
                    }
                </div>
            }

            <div className={`${styles.QueryResults}`}>
                {/* Query Viewer */}
                {chromaQL !== {query:{}} &&
                    <ChromaList query={chromaQL} limit={limit} setCount={setCount}/>
                }
            </div>
        </div>
    )
}

export default QueryBuilder